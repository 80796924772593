.phone-prefix-container {
    visibility: visible;
    width: 100%;
    top: 50px;
    left: 0px;
    overflow-y: auto;
}

.phone-prefix-flag {
    width: 28px;
    height: 18px;
}

.phone-prefix-show-options {
    width: 100%;
    opacity: 1;
    position: relative;
    left: 0;
}

.phone-prefix-unshow-options {
    width: 4px;
    opacity: 1;
    position: absolute;
    left: -10000px;
}

input[type="select-one"]{
    padding-right:18px !important;
}
